/* .board {
    margin-left: 3.85%;
    margin-right: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //padding-left: 4.3%;
} */



.row {
    display: table-row;
}

.square {
   display: table-cell;
   width: 85px;
   height: 85px;
}

.row:nth-child(odd) .square:nth-child(even), .row:nth-child(even) .square:nth-child(odd) {
	background: #b58863;
}

.row:nth-child(odd) .square:nth-child(odd), .row:nth-child(even) .square:nth-child(even) {
	background: beige;
}

/* .white-square {
    background-color: beige;
}

.black-square {
    background-color: #b58863;
} */

body {
    /* padding-left: 30%;
    padding-right: 30%; */
    display: table;
    background-color: black;
    height: 100%;
}

.piece {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    height: auto;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.board {
    padding-top: 6.75%;
  position: fixed;
  left: 0;
  width: 100%;
  align-content: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 4%;
  width: 100%;
  text-align: center;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
}

.checked {
    background: radial-gradient(ellipse at center, red 0%, #e70000 25%, rgba(169,0,0,0) 89%, rgba(158,0,0,0) 100%);
}